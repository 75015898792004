import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
import config from "../coreFIles/config";
import Header from '../directives/header'
import Footer from '../directives/footer'
import Pagination from '../directives/Pagination';
import Cookies from 'js-cookie'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { getParentCatlistAction, getmasterFilterAction, getChildCatlistAction, getAllProductsListAction, getTotalAllProductsAction, getFilterCountListAction, getUserProfileAction, getSearchSuburbAction, UpdateUserProfileAction, UpdateUserPasswordAction, getUserPendingQuotesAction, CreateNewQuoteAction, addToCartProductAction } from '../Action/action';
	const loginData = (!Cookies.get('loginSuccessfwMember')) ? [] : JSON.parse(Cookies.get('loginSuccessfwMember'));
	const Products = () => {
	const [homeSliders, sethomeSliders]	= useState({});
	const [pcategories, setpcategories]	= useState([]);
	const [childcats, setchildcats]		= useState([]);
	const [childcats2, setchildcats2]		= useState([]);
	const [childcats3, setchildcats3]		= useState([]);
	const [childcats4, setchildcats4]		= useState([]);
	const [childcats5, setchildcats5]		= useState([]);
	const [childcats6, setchildcats6]		= useState([]);
	const [pCatData, setPcat]				= useState({});
	const [CCatData1, setCcat1]				= useState({});
	const [CCatData2, setCcat2]				= useState({});
	const [CCatData3, setCcat3]				= useState({});
	const [CCatData4, setCcat4]				= useState({});
	const [CCatData5, setCcat5]				= useState({});
	const [CCatData6, setCcat6]				= useState({});
	const [finishData, setfinishData]		= useState({});
	const [materialData, setmaterialData]	= useState({});
	const [threadData, setthreadData]		= useState({});
	const [paintData, setpaintData]			= useState({});
	const [drivetypeData, setdrivetypeData]	= useState({});	
	const [producttypecount, setproducttypecount] = useState(0);
	const [pCatId, setPcatId]			= useState(0);
	const [child1, setchild1]			= useState(0);
	const [child2, setchild2]			= useState(0);
	const [child3, setchild3]			= useState(0);
	const [child4, setchild4]			= useState(0);
	const [child5, setchild5]			= useState(0);
	const [child6, setchild6]			= useState(0);
	const [finish, setfinish]			= useState(0);
	const [material, setmaterial]		= useState(0);
	const [thread, setthread]			= useState(0);
	const [paint, setpaint]				= useState(0);
	const [drivetype, setdrivetype]		= useState(0);
	const [Allproducts, setAllproducts]	= useState([]);
	const [filterscount, setfilterscount]	= useState({});
	const [masterfilters, setmasterfilters]	= useState([]);
	const [filterChilds, setfilterChilds]	= useState([]);
	const [page, setPage] 					= useState(1); 
	const [totalPages, settotalPages] 		= useState(0);
	const [totalRecords, settotalRecords] 	= useState(0);
	const [FilterCatID, setFilterCatID] 	= useState(0);
	const [pcatcount, setpcatcount] 		= useState(0);
	const [productName, setProductName] 	= useState('');
	const [validationError, setvalidationError] = useState({});
	const [pwvalidationError, setpwvalidationError] = useState({});
	const [UserProfile, setUserProfile] 	= useState({});
	const [suburb, setsuburb] 				= useState('');
	const [postcode, setpostcode] 			= useState('');
	const [SuburbsearchResults, setSuburbsearchResults] 	= useState([]);
	const [PendingQuotes, setPendingQuotes] 	= useState([]);
	const [spinLoader, setSpinLoader] = useState(0);
	const [spinpwLoader, setSpinpwLoader] = useState(0);
	const [quoteLoder, setquoteLoder] = useState(0);
	const [form, setForm] 	= useState({ email:loginData?.email, curpassword: '', password: '', cpassword: '' });
	const [QuoteselectedIndex, setQuoteselectedIndex] = useState(0);
	const [selectedQuoteId, setSelectedQuoteId] = useState(null);
	const [expandedProductRows, setexpandedProductRows] = useState({});
	
	useEffect(() => {
		if (!loginData || loginData == '') {
		window.location.href = `login`;
	  }	
	  geParentCategoriesAPI();
	  getmasterFilterAPI();
	  getUserProfileAPI();
	  getUserPendingQuotesAPI();
	},[])	
	// Fetch products when page or filter changes
	useEffect(() => {	  
		getAllProductsAPI();
		getTotalAllProductsAPI();    
	}, [page, pCatId, child1, child2, child3, child4, child5, child6, productName,finish,material,thread,paint,drivetype]);	
	useEffect(() => {   
		if (PendingQuotes.length > 0 && selectedQuoteId == null) {
		  setQuoteselectedIndex(PendingQuotes.length + 2);
		  setSelectedQuoteId(PendingQuotes[PendingQuotes.length - 1].id); 
		}
	}, [PendingQuotes]);
	const toggleProductRow = (productId) => {
		setexpandedProductRows((prevState) => ({
		  ...prevState,
		  [productId]: !prevState[productId],
		}));
	  };
	const CreateNewQuote = async () => {
		let res = await CreateNewQuoteAction();
		if (res.success) {
			toast.success(res.msg);
			getUserNewPendingQuotesAPI();			
		}
		else{
			toast.error(res.msg);
		}
				
	}
	const handleTabSelect = (index) => {
		if(index==1 || index==2 )
		{
			setQuoteselectedIndex(index);		
		}
		else
		{
			if(index == 0)
			{
				console.log(PendingQuotes.length);
				if (PendingQuotes.length > 0) {
				setQuoteselectedIndex(PendingQuotes.length + 2);		
				setSelectedQuoteId(PendingQuotes[PendingQuotes.length - 1].id); 
				}
			}
			else
			{	
						
				if(index <= PendingQuotes.length + 2)
				{
					setQuoteselectedIndex(index);		
					setSelectedQuoteId(PendingQuotes[index-3].id); 
				}
			}
			
		}
		
		
	};  
	const inputSearchHandler = async (e) => {
		const { name, value } = e.target
		setProductName(value);		
	}
	const clearsearchtext	= async () => {		  
		setProductName('');		
	}
	const clearallsearch	= async () => {	
		 window.location.reload();  
	}
	const inputHandler = async (e) => {
		const { name, value } = e.target
		setUserProfile((old) => {
			return { ...old, [name]: value }
		})
	}
	const inputPwHandler = async (e) => {
		const { name, value } = e.target
		setForm((old) => {
			return { ...old, [name]: value }
		})
	}
	const handleKeyPress = (e) => {
		// Allow only numeric values (0-9) and certain control keys
		const isValidInput = /^[0-9\b]+$/.test(e.key);

		if (!isValidInput) {
		  e.preventDefault();
		}
	  }; 
	const SearchSuburb = async (str) => {
		try {
			setsuburb(str);
			setpostcode('');
			let res = await getSearchSuburbAction({str:str});
			setSuburbsearchResults(res.data);
		}
		catch (error) {
			console.error(error);
		}
	};	   
	const handleSelectSuburb = (row) => {	   
		setsuburb(row.suburb);
		setpostcode(row.postcode);
		setUserProfile((old) => {
			return { ...old, 'suburb': row.suburb };
		});
		setUserProfile((old) => {
			return { ...old, 'postcode': row.postcode };
		});
		setSuburbsearchResults({});
	};			
	const getUserProfileAPI = async () => {
		let res = await getUserProfileAction();
		if (res.success) {
			let data = res.data;
			setsuburb(data.suburb);
			setpostcode(data.postcode);			
			setUserProfile(data);			
		}
		else
		{
			setUserProfile([]);				
		}
	}
	const getUserPendingQuotesAPI = async () => {
		setquoteLoder(1);
		let res = await getUserPendingQuotesAction();
		if (res.success) {
			let data = res.data;
			setPendingQuotes(data);		
			setquoteLoder(0);	
		}
		else
		{
			setPendingQuotes([]);
			setquoteLoder(0);					
		}
	}
	const getUserNewPendingQuotesAPI = async () => {
		setquoteLoder(1);
		let res = await getUserPendingQuotesAction();
		if (res.success) {
			let data = res.data;
			setPendingQuotes(data);		
			setquoteLoder(0);	
			if (data.length > 0) {
			  setQuoteselectedIndex(data.length + 2);
			  setSelectedQuoteId(data[data.length - 1].id); 
			}
		}
		else
		{
			setPendingQuotes([]);
			setquoteLoder(0);					
		}
	}
	function validate() {	   
		let first_nameError = "";
		let last_nameError = "";		
		let phoneError = "";		
		let addressError = "";
		let suburbError = "";
		let stateError = "";
		let postcodeError = "";		
		
		if (UserProfile.first_name === '') {
		  first_nameError = "First name is required."
		}
		if (UserProfile.last_name === '') {
			last_nameError = "Last name is required."
		}		
		if (UserProfile.phone === '') {
			phoneError = "Phone is required."
		}
		if (UserProfile.address === '') {
			addressError = "Address is required."
		}
		if (UserProfile.suburb === '') {
			suburbError = "Suburb is required."
		}
		if (UserProfile.state === '') {
			stateError = "State is required."
		}
		if (UserProfile.postcode === '') {
			postcodeError = "Postcode is required."
		}				
		if (first_nameError || last_nameError || phoneError || addressError|| suburbError|| stateError|| postcodeError) {
			setvalidationError({
			  first_nameError, last_nameError, phoneError, addressError, suburbError, stateError, postcodeError	})
			return false
		} else {
			setvalidationError({ first_nameError, last_nameError, phoneError, addressError, suburbError, stateError, postcodeError })
			return true
		}
	}
	const UpdateProfile = async (e) => {
		e.preventDefault()
		const isValid = validate();
		if (!isValid) {

		}
		else {
			setSpinLoader(1);
			let res = await UpdateUserProfileAction(UserProfile);
			if (res.success) {
				toast.success(res.msg);
				getUserProfileAPI();
				setSpinLoader(0);				
			} else {
				toast.error(res.msg);
				setSpinLoader(0);
			}
		}
	}
	function validatepwd() {	   
		let curpasswordError = "";
		let passwordError = "";		
		let cpasswordError = "";	
		
		if (form.curpassword === '') {
		  curpasswordError = "Current password is required."
		}
		if (form.password === '') {
			passwordError = "New password is required."
		}		
		if (form.cpassword === '') {
			cpasswordError = "Confirm password required."
		}
		
		if(form.password !='')
		{
			if (form.cpassword != form.password) {
				cpasswordError = "Confirm password required."
			}
		}
			
		if (curpasswordError || passwordError || cpasswordError) {
			setpwvalidationError({ curpasswordError, passwordError, cpasswordError	})
			return false
		} else {
			setpwvalidationError({ curpasswordError, passwordError, cpasswordError })
			return true
		}
	}	
	const UpdateUserPassword = async (e) => {
		e.preventDefault()
		const isValid = validatepwd();
		if (!isValid) {

		}
		else {
			setSpinpwLoader(1);
			let res = await UpdateUserPasswordAction(form);
			if (res.success) {
				toast.success(res.msg);				
				setSpinpwLoader(0);	
				setForm({ email:loginData?.email, curpassword: '', password: '', cpassword: '' });
						
			} else {
				toast.error(res.msg);
				setSpinpwLoader(0);
			}
		}
	}
	const geParentCategoriesAPI = async () => {
		let res = await getParentCatlistAction();
		if (res.success) {
			let data = res.data;			
			setpcategories(data);
			setproducttypecount(data.length);	
			setpcatcount(data.length);
		}
		else
		{
			setpcategories([]);	
			setproducttypecount(0);
		}
	}
	const getmasterFilterAPI = async () => {
		let res = await getmasterFilterAction();
		if (res.success) {
			let data = res.data;			
			setmasterfilters(data);			
		}
		else
		{
			setmasterfilters([]);				
		}
	}	
	//get products 
	const getAllProductsAPI = async () => {
		let res = await getAllProductsListAction({page:page,p_cat :pCatId, child1:child1, child2:child2, child3:child3, child4:child4, child5:child5, child6:child6, productName:productName, finish:finish, material:material, thread:thread, paint:paint, drivetype:drivetype});
		if (res.success) {
			let data = res.data;			
			setAllproducts(data);
					
		}
		else
		{
			setAllproducts([]);	
			
		}
	}
	const getTotalAllProductsAPI = async () => {
		settotalPages(0);
		settotalRecords(0);
		let res = await getTotalAllProductsAction({p_cat :pCatId, child1:child1, child2:child2, child3:child3, child4:child4, child5:child5, child6:child6, productName:productName, finish:finish, material:material, thread:thread, paint:paint, drivetype:drivetype});		
        if (res.success) {
            settotalPages(res.data);
            settotalRecords(res.totalRecords); 
		}
        else
        {
			settotalPages(0);
			settotalRecords(0);	
		}
	}
	const onPageChanged = async (data)  => {
		const { currentPage, totalPages, pageLimit } = data;
		const offset = (currentPage - 1) * pageLimit;
		let res = await getAllProductsListAction({page:offset,p_cat :pCatId, child1:child1, child2:child2, child3:child3, child4:child4, child5:child5, child6:child6, productName:productName, finish:finish, material:material, thread:thread, paint:paint, drivetype:drivetype});
		if (res.success) {
			let data = res.data;			
			setAllproducts(data);					
		}
		else
		{
			setAllproducts([]);				
		}
	}
	// set filters
	const SetParentCat = async (item) => {
		setPcat(item)
		setPcatId(item.id)		
		let res = await getChildCatlistAction({p_cat:item.id});
		if (res.success) {
			let data = res.data;			
			setchildcats(data);	
			setproducttypecount(data.length);
		}
		else
		{
			setchildcats([]);	
			setproducttypecount(0);
		}
	
	}
	const Setchild1 = async (item) => {
		setCcat1(item)
		setchild1(item.id)	
			
		let res = await getChildCatlistAction({p_cat:item.id});
		if (res.success) {
			if(item.child_count == 0)
			{
				setFilterCatID(item.id);
				getFilterAPI(item.id);
			}
			else
			{
				setfilterscount({});
				setfilterChilds([])
			}
			let data = res.data;			
			setchildcats2(data);	
			setproducttypecount(data.length);
		}
		else
		{
			setchildcats2([]);	
			setproducttypecount(0);
		}
	}
	const Setchild2 = async (item) => {
		setCcat2(item)
		setchild2(item.id)				
		let res = await getChildCatlistAction({p_cat:item.id});
		if (res.success) {
			if(item.child_count == 0)
			{
				setFilterCatID(item.id);
				getFilterAPI(item.id);
			}
			else
			{
				setfilterscount({});
				setfilterChilds([])
			}
			let data = res.data;			
			setchildcats3(data);
			setproducttypecount(data.length);	
		}
		else
		{
			setchildcats3([]);	
			setproducttypecount(0);
		}
	}
	const Setchild3 = async (item) => {
		setCcat3(item)
		setchild3(item.id)
		
		let res = await getChildCatlistAction({p_cat:item.id});
		if (res.success) {
			if(item.child_count == 0)
			{
				setFilterCatID(item.id);
				getFilterAPI(item.id);
			}
			else
			{
				setfilterscount({});
				setfilterChilds([])
			}
			let data = res.data;			
			setchildcats4(data);	
			setproducttypecount(data.length);
		}
		else
		{
			setchildcats4([]);
			setproducttypecount(0);	
		}
	}
	const Setchild4 = async (item) => {
		setCcat4(item)
		setchild4(item.id)		
		let res = await getChildCatlistAction({p_cat:item.id});
		if (res.success) {
			if(item.child_count == 0)
			{
				setFilterCatID(item.id);
				getFilterAPI(item.id);
			}
			else
			{
				setfilterscount({});
				setfilterChilds([])
			}
			let data = res.data;			
			setchildcats5(data);
			setproducttypecount(data.length);	
		}
		else
		{
			setchildcats5([]);	
			setproducttypecount(0);
		}
	}
	const Setchild5 = async (item) => {
		setCcat5(item)
		setchild5(item.id)			
		let res = await getChildCatlistAction({p_cat:item.id});
		if (res.success) {
			if(item.child_count == 0)
			{
				setFilterCatID(item.id);
				getFilterAPI(item.id);
			}
			else
			{
				setfilterscount({});
				setfilterChilds([])
			}
			let data = res.data;			
			setchildcats6(data);	
			setproducttypecount(data.length);
		}
		else
		{
			setchildcats6([]);	
			setproducttypecount(0);
		}
	}
	const Setchild6 = async (item) => {
		setCcat6(item)
		setchild6(item.id)	
		if(item.child_count == 0)
		{
			setFilterCatID(item.id);
			getFilterAPI(item.id);
		}
		else
		{
			setfilterscount({});
			setfilterChilds([])
		}	
		
	}
	const SetFilterfltr = async (item,filterType) => {
		 if (filterType == config.finish) {
			setfinishData(item);
			setfinish(item.child_id)
			getFilterAPIOnSelect(FilterCatID,productName,item.child_id,material,thread,paint,drivetype);
		  } else if (filterType == config.material) {
			setmaterialData(item);
			setmaterial(item.child_id)
			getFilterAPIOnSelect(FilterCatID,productName,finish,item.child_id,thread,paint,drivetype);
		  } else if (filterType == config.thread) {
			setthreadData(item);
			setthread(item.child_id)
			getFilterAPIOnSelect(FilterCatID,productName,finish,material,item.child_id,paint,drivetype);
		  } else if (filterType == config.paint) {
			setpaintData(item);
			setpaint(item.child_id)
			getFilterAPIOnSelect(FilterCatID,productName,finish,material,thread,item.child_id,drivetype);
		  } else if (filterType == config.drive) {
			setdrivetypeData(item);
			setdrivetype(item.child_id)
			getFilterAPIOnSelect(FilterCatID,productName,finish,material,thread,paint,item.child_id);
		  }
		  
		
	}
	const getFilterAPI = async (id) => {		
		let res = await getFilterCountListAction({cat_id :id, productName:productName, finish:finish, material:material, thread:thread, paint:paint, drivetype:drivetype});
		if (res.success) {
			let data = res.data;			
			let filtersdata = res.filtersdata;			
			setfilterscount(data);
			setfilterChilds(filtersdata)
					
		}
		else
		{
			setfilterscount({});
			setfilterChilds([])
			
		}
	}
	const getFilterAPIOnSelect = async (id,pname,fin,mat,th,pnt,driv) => {		
		let res = await getFilterCountListAction({cat_id :id, productName:pname, finish:fin, material:mat, thread:th, paint:pnt, drivetype:driv});
		if (res.success) {
			let data = res.data;			
			let filtersdata = res.filtersdata;			
			setfilterscount(data);
			setfilterChilds(filtersdata)
					
		}
		else
		{
			setfilterscount({});
			setfilterChilds([])
			
		}
	}
	// remove filters
	const RemoveParentCat = async (item) => {		
		setPcat({})
		setPcatId(0)
		setFilterCatID(0);
		setproducttypecount(pcatcount);			
		let res = await getChildCatlistAction({p_cat:0});
		if (res.success) {
			let data = res.data;			
			setchildcats(data);	
			
		}
		else
		{
			setchildcats([]);
			setproducttypecount(0);	
		}
	
	}
	const RemoveChild1 = async (item) => {
		setCcat1({})
		setCcat2({})
		setCcat3({})
		setCcat4({})
		setCcat5({})
		setCcat6({})
		setchild1(0)				
		setchild2(0)				
		setchild3(0)				
		setchild4(0)				
		setchild5(0)				
		setchild6(0)				
		let res = await getChildCatlistAction({p_cat:pCatId});
		if (res.success) {
			let data = res.data;			
			setchildcats(data);	
			setproducttypecount(data.length);
		}
		else
		{
			setchildcats([]);	
			setproducttypecount(0);
		}	
	}
	const RemoveChild2 = async (item) => {		
		setCcat2({})
		setCcat3({})
		setCcat4({})
		setCcat5({})
		setCcat6({})					
		setchild2(0)				
		setchild3(0)				
		setchild4(0)				
		setchild5(0)				
		setchild6(0)				
		let res = await getChildCatlistAction({p_cat:child1});
		if (res.success) {
			let data = res.data;			
			setchildcats2(data);	
			setproducttypecount(data.length);
		}
		else
		{
			setchildcats2([]);	
			setproducttypecount(0);
		}	
	}
	const RemoveChild3 = async (item) => {
		setCcat3({})
		setCcat4({})
		setCcat5({})
		setCcat6({})					
		setchild3(0)				
		setchild4(0)				
		setchild5(0)				
		setchild6(0)				
		let res = await getChildCatlistAction({p_cat:child2});
		if (res.success) {
			let data = res.data;			
			setchildcats3(data);	
			setproducttypecount(data.length);
		}
		else
		{
			setchildcats3([]);	
			setproducttypecount(0);
		}	
	}
	const RemoveChild4 = async (item) => {
		setCcat4({})
		setCcat5({})
		setCcat6({})					
		setchild4(0)				
		setchild5(0)				
		setchild6(0)				
		let res = await getChildCatlistAction({p_cat:child3});
		if (res.success) {
			let data = res.data;			
			setchildcats4(data);	
			setproducttypecount(data.length);
		}
		else
		{
			setchildcats4([]);
			setproducttypecount(0);	
		}	
	}
	const RemoveChild5 = async (item) => {		
		setCcat5({})
		setCcat6({})					
		setchild5(0)				
		setchild6(0)				
		let res = await getChildCatlistAction({p_cat:child4});
		if (res.success) {
			let data = res.data;			
			setchildcats5(data);	
			setproducttypecount(data.length);
		}
		else
		{
			setchildcats5([]);	
			setproducttypecount(0);
		}	
	}
	const RemoveChild6 = async (item) => {		
		setCcat6({})					
		setchild6(0)				
		let res = await getChildCatlistAction({p_cat:child5});
		if (res.success) {
			let data = res.data;			
			setchildcats6(data);
			setproducttypecount(data.length);	
		}
		else
		{
			setchildcats6([]);	
			setproducttypecount(0);
		}	
	}
	const RemoveFilter = async (item) => {		
		 if (item.type == config.finish) {
			setfinishData({});
			setfinish(0)
			getFilterAPIOnSelect(FilterCatID,productName,0,material,thread,paint,drivetype);
		  } else if (item.type == config.material) {
			setmaterialData({});
			setmaterial(0)
			getFilterAPIOnSelect(FilterCatID,productName,finish,0,thread,paint,drivetype);
		  } else if (item.type == config.thread) {
			setthreadData({});
			setthread(0)
			getFilterAPIOnSelect(FilterCatID,productName,finish,material,0,paint,drivetype);
		  } else if (item.type == config.paint) {
			setpaintData({});
			setpaint(0)
			getFilterAPIOnSelect(FilterCatID,productName,finish,material,thread,0,drivetype);			
		  } else if (item.type == config.drive) {
			setdrivetypeData({});
			setdrivetype(0)
			getFilterAPIOnSelect(FilterCatID,productName,finish,material,thread,paint,0);
		  }	
	}
	// add to cart
	const AddToCart = async (product)  => {
		let res = await addToCartProductAction({product_id:product.id,hobson_part:product.hobson_part,cart_id:selectedQuoteId});
		if (res.success) {	
			toast.success(res.msg);				
			getUserPendingQuotesAPI();					
		}
		else
		{
			toast.error(res.msg);	
		}		
	}
	console.log(selectedQuoteId);	
	return(
		<>
			<Header />
			 <Toaster />
			<div className="wrapper-content-banner">
				<div id="cartsection">
					<div data-tax="10.00" id="tax_class"></div>
					<div className="container">
						<div id="fixedcart" className="cart-inner-block">
							<Tabs selectedIndex={QuoteselectedIndex} onSelect={handleTabSelect}>
								<TabList className="account-box">
									<Tab className="nav-item cartsectionheader">Cart</Tab>									
									<Tab>ACCOUNT</Tab>
									<Tab>ORDERS</Tab> 
									{quoteLoder === 0 ? (
									  PendingQuotes.length > 0 ? (
										PendingQuotes.map((pq) => (
										  <Tab key={pq.id}>#{pq.quote_code}</Tab>
										))
									  ) : null
									) : null}

									
									<Tab onClick={CreateNewQuote}>+</Tab>       
								</TabList>									
								<TabPanel>	
									 <div className="company-name">
								 <h2 id="minimum_order_total"><span>CM Marketing</span> Cart </h2>
								 </div>
								</TabPanel>	
								<TabPanel>	
									 <div className="company-name">
										<h2 id="minimum_order_total"><span>CM Marketing</span> ACCOUNT </h2>
										</div>
										<div className="registration-heading mt-3">	
											<h2>Account details</h2>						
											<div className="form-section section-register">
												<div className="form-row"> 
													<div className="form-group col-md-6">
														<label for="first_name">First Name<span className="req-star">*</span></label>
														<input type="text" className={`form-control ${validationError.first_nameError ? 'is-invalid' : ''}`}  id="first_name" name="first_name"  placeholder="Enter first name" onChange={inputHandler}  value={UserProfile.first_name}/>
													</div>
													<div className="form-group col-md-6">
														<label for="last_name">Last Name<span className="req-star">*</span></label>
														<input type="text" className={`form-control ${validationError.last_nameError ? 'is-invalid' : ''}`} id="last_name" name="last_name" placeholder="Enter last namee"  onChange={inputHandler}  value={UserProfile.last_name}/>
													</div>												
												<div className="form-group col-md-6">
													<label for="email">Email Address*</label>
													<input type="email" className={`form-control ${validationError.emailError ? 'is-invalid' : ''}`} id="email" name="email" readonly disabled placeholder="Enter email address" onChange={inputHandler} value={UserProfile.email} />
												</div>												
												<div className="form-group col-md-6">
													<label for="phone">Phone *</label>
													<input type="text" className={`form-control ${validationError.phoneError ? 'is-invalid' : ''}`} id="phone" name="phone" placeholder="Enter phone" onChange={inputHandler} value={UserProfile.phone} onKeyPress={handleKeyPress} maxlength="10" />
												</div>										
												<div className="form-group col-md-6">
													<label for="company">Company name*</label>
													<input type="text" className={`form-control ${validationError.companyError ? 'is-invalid' : ''}`} id="company" name="company" placeholder="Enter company name" readonly disabled value={UserProfile.company} />
												</div>
												<div className="form-group col-md-6">
													<label for="address">Address *</label>
													<input type="text" className="form-control" id="address" name="address" placeholder="Enter address" onChange={inputHandler} value={UserProfile.address}/>
												</div>
												<div className="form-group col-md-6">
												<label for="exampleInputEmail1">Suburb *</label>
													<input type="text" className="form-control" id="suburb" name="suburb" placeholder="Enter suburb" autocomplete="off" onChange={(e) => SearchSuburb(e.target.value)}  value={suburb} />
													{SuburbsearchResults.length > 0 ? (
														<ul className="suburbList">
															{SuburbsearchResults.map((row) => (
																<li key={row.id} onClick={() => handleSelectSuburb(row)}>
																	{row.rowlocation} 
																</li>
															))}
														</ul>
													) : null}
												</div>
												<div className="form-group col-md-6">
													<label for="exampleInputEmail1">State *</label>
													<select className="form-control" id="state" name="state" onChange={inputHandler} value={UserProfile.state}>
													  <option>Select</option>
													  <option value="ACT">Australian Capital Territory</option>
													  <option value="NSW">New South Wales</option>
													  <option value="NT">Northern Territory</option>
													  <option value="QLD">Queensland</option>
													  <option value="SA">South Australia</option>
													  <option value="TSA">Tasmania</option>
													  <option value="VIC">Victoria</option>
													  <option value="WA">Western Australia</option>
													</select>	
												</div>
												<div className="form-group col-md-6">
													<label for="postcode">Postcode *</label>
													<input type="postcode" value={postcode} class="form-control" id="postcode" name="postcode" placeholder="Enter postcode" readonly disabled />
												</div>
											</div>
										</div>
										<div className="login-button text-right">
											<button className="login-btn" onClick={UpdateProfile} disabled={spinLoader==1 ? true : ''} >{spinLoader==0 ? 'UPDATE DETAILS' : 'UPDATING...' }</button>
										</div>
									</div>
									<div className="registration-heading mt-3">	
										<h2>Change Password</h2>						
										<div className="form-section section-register">
											<div className="form-row"> 
											<div className="form-group col-md-4">
												<label for="curpassword">Current Password *</label>
												<input type="password" className={`form-control ${pwvalidationError.curpasswordError ? 'is-invalid' : ''}`} id="curpassword" placeholder="Enter Current Password" name="curpassword" onChange={inputPwHandler} value={form.curpassword} />
											</div>
											<div className="form-group col-md-4">
												<label for="password">New Password *</label>
												<input type="password" className={`form-control ${pwvalidationError.passwordError ? 'is-invalid' : ''}`} id="password" placeholder="Enter New Password" name="password" onChange={inputPwHandler} value={form.password} />
											</div>
											<div className="form-group col-md-4">
												<label for="cpassword">Confirm Password *</label>
												<input type="password" className={`form-control ${pwvalidationError.cpasswordError ? 'is-invalid' : ''}`} id="cpassword" placeholder="Enter Confirm Password" name="cpassword" onChange={inputPwHandler} value={form.cpassword} />
											</div>											
										</div>
										</div>
										<div className="login-button text-right">
											<button className="login-btn" onClick={UpdateUserPassword} disabled={spinpwLoader==1 ? true : ''}>{spinpwLoader==0 ? 'UPDATE PASSWORD' : 'UPDATING...' }</button>
										</div>
									</div>
								</TabPanel>	
								<TabPanel>	
									 <div className="company-name">
										<h2 id="minimum_order_total"><span>CM Marketing</span> ORDERS </h2>
								  </div>
								</TabPanel>	
						{quoteLoder === 0 ? (
							  PendingQuotes.length > 0 ? (
								PendingQuotes.map((pq) => (
								  <TabPanel key={pq.id}>
									<div className="company-name">
									  <h2 id="minimum_order_total">
										<span>CM Marketing</span> #{pq.quote_code}
									  </h2>
									</div>
									{pq.quote_products && pq.quote_products.length > 0 ? (
									  <div className="table-responsive product-table">
										<table className="table table-condensed table-striped">
										  <thead>
											<tr>
											  <th>PART</th>
											  <th>DESCRIPTION</th>
											  <th>PRODUCT</th>
											  <th>PRICE</th>
											  <th>QUANTITY</th>
											  <th>SUB TOTAL</th>
											  <th></th>
											</tr>
										  </thead>
										  <tbody>
											{pq.quote_products.map((quote_product) => (
											  <React.Fragment key={quote_product.product_id}>
												<tr
												  id={`cart_item_${quote_product.product_id}`}
												  className="main-sec-table"
												  onClick={() => toggleProductRow(quote_product.product_id)}
												  style={{ cursor: 'pointer' }}
												>
												  <td>{quote_product.product_sku}</td>
												  <td>{quote_product.product_description}</td>
												  <td>{quote_product.product_extra_description}</td>
												  <td>${quote_product.product_per_price}</td>
												  <td>{quote_product.product_quantity}</td>
												  <td className="item_price">
													${quote_product.product_quantity * quote_product.product_per_price}
												  </td>
												  <td>{expandedProductRows[quote_product.product_id] ? '-' : '+'}</td>
												</tr>
												{expandedProductRows[quote_product.product_id] && (
												  <tr className="product-details-row">
													<td colSpan="7">
													  <div className="product-details">
														<p><strong>Product SKU:</strong> {quote_product.product_sku}</p>
														<p><strong>Description:</strong> {quote_product.product_description}</p>
														<p><strong>Extra Description:</strong> {quote_product.product_extra_description}</p>
														<p><strong>Quantity:</strong> {quote_product.product_quantity}</p>
														<p><strong>Per Price:</strong> ${quote_product.product_per_price}</p>
													  </div>
													</td>
												  </tr>
												)}
											  </React.Fragment>
											))}
										  </tbody>
										</table>
									  </div>
									) : (
									  <div className="table-responsive product-table">
										<table className="table table-condensed table-striped">
										  <thead>
											<tr>
											  <th>PART</th>
											  <th>DESCRIPTION</th>
											  <th>PRODUCT</th>
											  <th>PRICE</th>
											  <th>QUANTITY</th>
											  <th>SUB TOTAL</th>
											  <th></th>
											</tr>
										  </thead>
										  <tbody>
											<tr className="strip-bg text-center">
											  <td colSpan="7">You do not have any products in this order.</td>
											</tr>
										  </tbody>
										</table>
									  </div>
									)}
								  </TabPanel>
								))
							  ) : null
							) : null}


								
								
							</Tabs>
						 </div>
						</div>
					 </div>
{ QuoteselectedIndex !=1 && QuoteselectedIndex !=2 ?(					 
<div className="container">
<div className="background-color productnew-sections">
  <div className="row align-items-center">
	<div className="col-lg-2">
	  <h2 className="products_title">PRODUCTS</h2>
	</div>
	<div className="col-lg-6">
	  <div className="search-product"> <a href="javascript:;" onClick={clearallsearch} className="newsearch">New search</a>		
		<div className="searchform">
		  <input type="text" name="search" id="search" value={productName} placeholder="Search Products" fdprocessedid="twezrt"  onChange={inputSearchHandler}/>
		  {productName !='' ? (<a href="javascript:;" class="clearsearch" onClick={clearsearchtext} >X</a>) :''}
		  <p id="searchalert" className="searchalert d-none">You must enter a min of 4 characters.</p>
		</div>
	  </div>
	</div>
	<div className="col-lg-4 pagination-container">
	  <div className="pages-link">		
	  </div>
	</div>
  </div>
</div>  
<div className="main-section-tabs">
  <div className="col-md-12">
	<div className="tabs-section">
	  <ul className="nav nav-tabs" role="tablist">
		<li className="nav-item"> <a className="nav-link" data-toggle="tab" href="#tabs-1" role="tab">
		  <h6>PRODUCT TYPE {producttypecount > 0 ? `(${producttypecount})` : ''}</h6>
		  </a> </li>
		  {pCatId != 0 ? (
		   <li className="cattabs_open"><a href="javascript:;"> {pCatData?.description}<span class="catclose" onClick={() => RemoveParentCat(pCatData)}>&nbsp;X</span></a></li>
		  ):''}
		  
		  {child1 != 0 ? (
		   <li className="cattabs_open"><a href="javascript:;"> {CCatData1?.description}<span class="catclose" onClick={() => RemoveChild1(CCatData1)}>&nbsp;X</span></a></li>
		  ):''}
		  
		  {child2 != 0 ? (
		   <li className="cattabs_open"><a href="javascript:;"> {CCatData2?.description}<span class="catclose" onClick={() => RemoveChild2(CCatData2)}>&nbsp;X</span></a></li>
		  ):''}
		  
		  {child3 != 0 ? (
		   <li className="cattabs_open"><a href="javascript:;"> {CCatData3?.description}<span class="catclose" onClick={() => RemoveChild3(CCatData3)}>&nbsp;X</span></a></li>
		  ):''}
		  
		  {child4 != 0 ? (
		   <li className="cattabs_open"><a href="javascript:;"> {CCatData4?.description}<span class="catclose" onClick={() => RemoveChild4(CCatData4)}>&nbsp;X</span></a></li>
		  ):''}
		  
		  {child5 != 0 ? (
		   <li className="cattabs_open"><a href="javascript:;"> {CCatData5?.description}<span class="catclose" onClick={() => RemoveChild5(CCatData5)}>&nbsp;X</span></a></li>
		  ):''}
		  
		  {child6 != 0 ? (
		   <li className="cattabs_open"><a href="javascript:;"> {CCatData6?.description}<span class="catclose" onClick={() => RemoveChild6(CCatData6)}>&nbsp;X</span></a></li>
		  ):''}
		  		
	  </ul>
	  <div className="tab-content">
		<div className="tab-pane active" id="tabs-1" role="tabpanel">
		  <div className="main-tabs-section">
			  {pcategories.length > 0 && pCatId===0 ? (
				pcategories.map((pcat) => (
					<div className="bolts-post">
					  <div className="images-bogls-contant">
						<div className="shapes" onClick={() => SetParentCat(pcat)}> 
							<img src={pcat.image_url} /> </div>
					  </div>
					  <div className="fasteners-contant" onClick={() => SetParentCat(pcat)}>
						<p>{pcat.description}</p>
					  </div>
					</div>
				))
			) : null} 
			
			{childcats.length > 0 && child1===0 && pCatId != 0 ? (
				childcats.map((ccat) => (
					<div className="bolts-post">
					  <div className="images-bogls-contant">
						<div className="shapes" onClick={ () => Setchild1(ccat)}>
							<img src={ccat.image_url} /> </div>
					  </div>
					  <div className="fasteners-contant" onClick={ () => Setchild1(ccat)}>
						<p>{ccat.description}</p>
					  </div>
					</div>
				))
			) : null}
			
			{childcats2.length > 0 && child2===0 && child1!= 0 && pCatId != 0 ? (
				childcats2.map((ccat2) => (
					<div className="bolts-post">
					  <div className="images-bogls-contant">
						<div className="shapes" onClick={ () => Setchild2(ccat2) }> 
							<img src={ccat2.image_url} /> </div>
					  </div>
					  <div className="fasteners-contant" onClick={ () => Setchild2(ccat2) }>
						<p>{ccat2.description}</p>
					  </div>
					</div>
				))
			) : null}
			
			{childcats3.length > 0 && child3===0 && child2 != 0 && child1!= 0 && pCatId != 0 ? (
				childcats3.map((ccat3) => (
					<div className="bolts-post">
					  <div className="images-bogls-contant">
						<div className="shapes" onClick={ () => Setchild3(ccat3) }> 
							<img src={ccat3.image_url} /> </div>
					  </div>
					  <div className="fasteners-contant" onClick={ () => Setchild3(ccat3) }>
						<p>{ccat3.description}</p>
					  </div>
					</div>
				))
			) : null}
			
			{childcats4.length > 0 && child4===0 && child3 != 0 && child2 != 0 && child1!= 0 && pCatId != 0 ? (
				childcats4.map((ccat4) => (
					<div className="bolts-post">
					  <div className="images-bogls-contant">
						<div className="shapes" onClick={ () => Setchild4(ccat4)}> 
							<img src={ccat4.image_url} /> </div>
					  </div>
					  <div className="fasteners-contant" onClick={ () => Setchild4(ccat4)}>
						<p>{ccat4.description}</p>
					  </div>
					</div>
				))
			) : null}
			
			{childcats5.length > 0 && child5===0 && child4 != 0  && child3 != 0 && child2 != 0 && child1!= 0 && pCatId != 0 ? (
				childcats5.map((ccat5) => (
					<div className="bolts-post">
					  <div className="images-bogls-contant">
						<div className="shapes" onClick={ () => Setchild5(ccat5)}> 
							<img src={ccat5.image_url} /> </div>
					  </div>
					  <div className="fasteners-contant" onClick={ () => Setchild5(ccat5) }>
						<p>{ccat5.description}</p>
					  </div>
					</div>
				))
			) : null}
			
			{childcats6.length > 0 && child6===0 && child5 != 0 && child4 != 0 && child3 != 0 && child2 != 0 && child1!= 0 && pCatId != 0 ? (
				childcats6.map((ccat6) => (
					<div className="bolts-post">
					  <div className="images-bogls-contant">
						<div className="shapes"> 
							<img src={ccat6.image_url} /> </div>
					  </div>
					  <div className="fasteners-contant">
						<p>{ccat6.description}</p>
					  </div>
					</div>
				))
			) : null}
			
			
			
			</div>
		</div>		
	  </div>
	</div>	
	{FilterCatID !=0 ? (
	<Tabs className="tabs-btn tabbable">
	<TabList className="nav nav-tabs" id="filtersTab">
		
		{masterfilters.length > 0 ? (
		  masterfilters.map((fltr,index) => (
				<Tab disabled={fltr.id==config.finish && finish !=0 ? true : fltr.id==config.material && material !=0 ? true : fltr.id==config.thread && thread  !=0  ? true : fltr.id==config.paint && paint !=0 ? true : fltr.id==config.drive && drivetype !=0 ?  true :'' }>{fltr.description} ({fltr.id==config.finish ? filterscount.finish : fltr.id==config.material ? filterscount.material : fltr.id==config.thread   ? filterscount.thread : fltr.id==config.paint ? filterscount.paint : fltr.id==config.drive ? filterscount.drive :'0' })</Tab>
			))): null}
		  
		   {finish != 0 ? (
		   <li className="filtertab_open"><a href="javascript:;"> {finishData?.description}<span class="catclose" onClick={() => RemoveFilter(finishData)}>&nbsp;X</span></a></li>
		  ):''}
		  
		   {material != 0 ? (
		   <li className="filtertab_open"><a href="javascript:;"> {materialData?.description}<span class="catclose" onClick={() => RemoveFilter(materialData)}>&nbsp;X</span></a></li>
		  ):''}
		   {thread != 0 ? (
		   <li className="filtertab_open"><a href="javascript:;"> {threadData?.description}<span class="catclose" onClick={() => RemoveFilter(threadData)}>&nbsp;X</span></a></li>
		  ):''}
		   {paint != 0 ? (
		   <li className="filtertab_open"><a href="javascript:;"> {paintData?.description}<span class="catclose" onClick={() => RemoveFilter(paintData)}>&nbsp;X</span></a></li>
		  ):''}
		   {drivetype != 0 ? (
		   <li className="filtertab_open"><a href="javascript:;"> {drivetypeData?.description}<span class="catclose" onClick={() => RemoveFilter(drivetypeData)}>&nbsp;X</span></a></li>
		  ):''}
	</TabList>	
		{filterChilds.length > 0 ? (
			filterChilds.map((fl) => (			 
				<TabPanel  key={fl.filter_id} className="filter-group">	
					<div className="main-tabs-section">
				  {fl.children.map((child) => (
					   <div className="bolts-post" key={child.child_id}>
						  <div className="images-bogls-contant">
							<div className="shapes" onClick={() => SetFilterfltr(child,fl.filter_id)}> <img src={child.image_url} alt={child.title} /></div>
						  </div>
						  <div className="fasteners-contant" onClick={() => SetFilterfltr(child,fl.filter_id)}>
							<p>{child.description}</p>
						  </div>
						</div>					
				  ))}
				  </div>		
				</TabPanel>	
			))
		  ) :null}
	</Tabs>	 
	):''} 
	
	  <div className="table-responsive product-table">
		  <table className="table">
			<thead>
			  <tr>
				<th scope="col">PART</th>
				<th scope="col">DESCRIPTION</th>
				<th scope="col">PRODUCT</th>
				<th scope="col">FINISH</th>
				<th scope="col">SIZE</th>
				<th scope="col">LENGTH</th>
			  </tr>
			</thead>
			<tbody>
			{Allproducts.length > 0 ? (
				Allproducts.map((product,index) => (
					<tr className={index%2==0 ? 'strip-bg cr-ptr':'cr-ptr'}  onClick={() => AddToCart(product)} title="Add to cart">
					<td>{product.hobson_part}</td>
					<td>{product.description}</td>
					<td>{product.extra_description}</td>
					<td>{product.finish}</td>
					<td>{product.size}</td>
					<td>{product.length}</td>
				  </tr>
				))
			) : null}			 
			</tbody>
		  </table>		  
	</div>
	{totalPages > 0 ?										  
			<Pagination totalRecords={totalRecords} totalPages={totalPages} pageNeighbours={page} onPageChanged={onPageChanged}   />
			: ''
		}	
  </div>
</div>
</div>)
:''}
</div>
			
			<Footer />
		</>
  	)
}

export default Products;
