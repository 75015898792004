import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import config from "../coreFIles/config";
import Header from '../directives/header'
import Footer from '../directives/footer'
import Cookies from 'js-cookie'
import {  } from '../Action/action';
const loginData = (!Cookies.get('loginSuccessfwMember')) ? [] : JSON.parse(Cookies.get('loginSuccessfwMember'));
const Home = () => {
	const [homeSliders, sethomeSliders]	= useState({});
	

	useEffect(() => {
		if (!loginData || loginData == '') {
		window.location.href = `${config.baseUrl}/login`;
	  }	
		
	},[])		
	
	return(
		<>
			<Header />	
			
			<div className="wrapper-content-banner">
				<div className="container">
					<div className="wrapper-content">
						<h1>LOGIN</h1>
					</div>
					<div classNameName="background-color"> 
					</div>
			<div className="registration">
				<div className="row">
				<div className="col-md-5">
						<div className="registration-heading login-heading">
							<h2>LOGIN BELOW</h2>
						<div className="form-section">
							<div className="form-group col-12">
								<label for="exampleInputEmail1">Username or email address *</label>
								<input type="text" className="form-control" id="inputAddress" placeholder="" />
								</div>
								<div className="form-group col-12">
									<label for="exampleInputEmail1">Password *</label>
								   <input type="password" className="form-control" id="inputPassword3" />
								</div>
						<input className="checklabel" type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
			 <label  for="vehicle1">Remember me</label><br />
						<a href="#">Click here if you forgotten your password?</a>
						
						<div className="login-button">
						<button className="login-btn">LOGIN</button>
					  </div>
						</div>
						</div>
						</div>
						<div className="col-md-7">
							<div className="registration-heading">
							<h2>DON'T HAVE A LOGIN? REGISTER BELOW FOR EARLY ACCESS</h2>
								<p>Register now for a chance to gain early access to exclusive features and content. Be among the first to explore new opportunities in our platform, designed to enhance your experience. Secure your spot today and stay ahead of the curve!</p>
							<div className="form-section section-register">
			<div className="form-row"> 
						<div className="form-group col-md-6">
				   <label for="exampleInputEmail1">First Name</label>
			<input type="text" className="form-control" id="inputEmail4" placeholder="" />
			</div>
			<div className="form-group col-md-6">
				<label for="exampleInputEmail1">Last Name</label>
			<input type="text" className="form-control" id="inputEmail4" placeholder="" />
			</div>
			</div>
			 <div className="form-group">
									<label for="exampleInputEmail1">Email Address*</label>
									<input type="email" className="form-control" id="inputEmail3" />
								</div>
								
								   <label for="exampleInputEmail1">Password *</label>
								<div className="form-row"> 
									
								<div className="form-group col-md-6">
			  
								<input type="text" className="form-control" id="inputAddress" placeholder="Enter Password" />
			</div>
			<div className="form-group col-md-6">
			  
								<input type="text" className="form-control" id="inputAddress" placeholder="Confirm Password" />
			</div>
			</div>
								
								<div className="form-group">
									<label for="exampleInputEmail1">Phone *</label>
								<input type="text" className="form-control" id="inputAddress" placeholder="" />
								</div>
								 <div className="form-row"> 
								<div className="form-group col-md-6">
				   <label for="exampleInputEmail1">Company name*</label>
			<input type="text" className="form-control" id="inputEmail4" placeholder="" />
			</div>
			<div className="form-group col-md-6">

				<label for="exampleFormControlSelect1">Industry *</label>
				<select className="form-control" id="exampleFormControlSelect1">
				  <option>Select</option>
				  <option>Construction</option>
				  <option>Electricity, Gas, Water and Waste Services</option>
				  <option>Personal Services</option>
				  <option>Wholesale Trade</option>
					<option>Transport, Postal and Warehousing</option>
				</select>

			</div>
			</div>

					  </div>
					  <div className="login-button">
						<button className="login-btn">REGISTER</button>
					  </div>
					</div>
					  </div>
					  </div>
						</div>
			</div>
			</div>

			<Footer />
		</>
  	)
}

export default Home;
