const config = {
    baseUrl: '/',
    //apiUrl: "http://localhost:3008/api/",
   // imageUrl: 'http://localhost:3008/api/uploads/',
    //bannersUrl: 'http://localhost:3008/api/banners/',   
    apiUrl: "https://fastener-front.lyonsdemo.in/apis/api/",
    imageUrl: 'https://fastener-front.lyonsdemo.in/apis/api/uploads/',
    bannersUrl: 'https://fastener-front.lyonsdemo.in/apis/api/banners/',   
    finish: 1,
    material: 2,
    thread: 3,
    paint: 4,
    drive: 5,
    superAdmin: 1,
    paging_size: 20
}
export default config;
