import './App.css';
import config from "./coreFIles/config";
import Home from './component/home';
import Products from './component/products';
import Login from './component/login';

import { BrowserRouter, Route, Routes } from 'react-router-dom'

	function App() {
		return (
		<BrowserRouter>
			<div>
				<Routes>					
					 <Route path={`${config.baseUrl}`} element={<Products />} />
					 <Route path={`${config.baseUrl}/login`} element={<Login />} />
				</Routes>
			</div>
		</BrowserRouter>
		);
	}


export default App;
